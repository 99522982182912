const postMonsterAi = async (img, year, person, glasses, hair, mobile, image_name) => {
  
    let url = 'https://app.admind.ai/lemonsoda/img/'
    //let url = 'http://192.168.1.9:8000/lemonsoda/img/'

    let json = {
        img: img, 
        person: person,
        glasses: glasses,
        hair: hair,
        year: year,
        origin: mobile ? 1 : 0,
        localization: 'UNDEFINED'
        //age: age,
        //eyes: eyes
    }

    if (image_name){
        json = {
            ...json,
            image_name: image_name
        }
    }
    
    let result = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(json)
    })

    let dados = await result.json();
    console.log('photo changes:', dados.description)
    if (result.status===200){
        return {
            status: 200,
            url: `${url}?name=${dados.name}`,
            image_name: dados.image
        };
    }else {
        return {
            status: 404,
             message: 'error'
        };
    }
    

}

export default postMonsterAi