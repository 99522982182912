import React from 'react'
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom"
import './index.css'


// Components
import NotFound from './404'
import App from './App'

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  
  <React.StrictMode>
     
    <BrowserRouter>
   
      <Switch>
        <Route exact path="/" render={() => <App path={"home"}  />} />
        <Route path="/xjdi29330" render={() => <App path={"xjdi29330"}  />} />
        <Route path="/ghks52750" render={() => <App path={"ghks52750"}  />} />
        <Route path="/plmq82660" render={() => <App path={"plmq82660"}  />} />
        <Route path="/qkzp13570" render={() => <App path={"qkzp13570"}  />} />
        <Route path="/uyhr79480" render={() => <App path={"uyhr79480"}  />} />
        <Route path="/wvbx63090" render={() => <App path={"wvbx63090"}  />} />
        
        <Route component={NotFound} />
      </Switch>
      
    </BrowserRouter>
    
    
  </React.StrictMode>
)

