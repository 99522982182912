import OpenAI from "openai";

const create_openAI = () => {
    const apiKey = process.env.REACT_APP_OPENAI_KEY;
    if (!apiKey) {
        throw new Error("API Key is missing. Please provide a valid key.");
    }

    return new OpenAI({ apiKey, dangerouslyAllowBrowser: true });
}

export default create_openAI