import oranSodaSvg from './images/oran-soda.svg'
import lemonSodaSvg from './images/lemon-soda.svg'
import share from './images/share.svg'
import downloadIcon from './images/download.svg'
import anni30Cover from './images/anni-30/cover.svg'
import backAnni30Cover from './images/anni-30/back-cover.svg'
import backAnni30Final from './images/anni-30/final.svg'
import anni50Cover from './images/anni-50/cover.svg'
import backAnni50Cover from './images/anni-50/back-cover.svg'
import backAnni50Final from './images/anni-50/final.svg'
import anni60Cover from './images/anni-60/cover.svg'
import backAnni60Cover from './images/anni-60/back-cover.svg'
import backAnni60Final from './images/anni-60/final.svg'
import anni70Cover from './images/anni-70/cover.svg'
import backAnni70Cover from './images/anni-70/back-cover.svg'
import backAnni70Final from './images/anni-70/final.svg'
import anni80Cover from './images/anni-80/cover.svg'
import backAnni80Cover from './images/anni-80/back-cover.svg'
import backAnni80Final from './images/anni-80/final.svg'
import anni90Cover from './images/anni-90/cover.svg'
import backAnni90Cover from './images/anni-90/back-cover.svg'
import backAnni90Final from './images/anni-90/final.svg'
import CircularProgress from '@mui/material/CircularProgress'
import cameraOrange from './images/camera-orange.svg'
import cameraYellow from './images/camera-yellow.svg'
import upload from './images/upload.svg'
import lattinaAllPng from './images/lattine-all.png'
import lattinaPrimaFilaPng from './images/lattine-prima-fila.png'
import lattinaSecondaFilaPng from './images/lattine-seconda-fila.png'
import LinearProgress from '@mui/material/LinearProgress'
import Cookies from 'js-cookie'
import XIcon from '@mui/icons-material/X'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckIcon from '@mui/icons-material/Check'
import { Dialog } from '@material-ui/core'
import InstagramIcon from '@mui/icons-material/Instagram'
import coverAnni30 from './images/anni-30.png'
import coverAnni50 from './images/anni-50.png'
import coverAnni60 from './images/anni-60.png'
import coverAnni70 from './images/anni-70.png'
import coverAnni80 from './images/anni-80.png'
import coverAnni90 from './images/anni-90.png'
import lastPageOrange from './images/last-page-orange.png'
import lastPageYellow from './images/last-page-yellow.png'
import sfondoGiallo from './images/sfondo-desktop-giallo.png'
import sfondoOrange from './images/sfondo-desktop-orange.png'

import {
    FacebookShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    TwitterShareButton,
    EmailShareButton,
    WhatsappIcon,
    FacebookIcon,
    TelegramIcon,
    EmailIcon
} from 'react-share'
import './App.css'
import { useEffect, useRef, useState } from 'react'
import openAIVision from './services/openAIVision'
import postMonsterAi from './services/postMonsterAi'
import downloadImage from './utils/downloadImage'
import downloadImage2 from './utils/downloadImage2'
import isMobile from './utils/isMobile'
import TutorialModal from './components/tutorialModal'
import { Fade } from "@mui/material";
import TutorialModal2 from './components/tutorialModal2'
import { Box } from '@mui/material'
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';

export const faro = initializeFaro({
    url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/cecd870838e8dde7916059b04c35d700',
    app: {
      name: 'lemonsoda',
      version: '1.0.0',
      environment: 'production'
    },
    
    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations(),
  
      // Initialization of the tracing package.
      // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
      new TracingInstrumentation(),
    ],
})

const iconsMap = {
    'xjdi29330': {
        sfondo: sfondoGiallo,
        lastPage: lastPageYellow,
        backgroundImage: coverAnni30,
        logo: lemonSodaSvg,
        cover: anni30Cover,
        backCover: backAnni30Cover,
        camera: cameraYellow,
        title: "'30",
        final: backAnni30Final,
        color: '#fed303',
        testo1: "IMMERGITI NELL'ELEGANZA DEGLI ANNI '30",
        testo2: "Esplora l’era dell’Art Déco e del Jazz.",
        testo3: "L’intelligenza artificiale ti farà rivivere il glamour unico di quel decennio indimenticabile."

    },
    'ghks52750': {
        sfondo: sfondoOrange,
        lastPage: lastPageOrange,
        backgroundImage: coverAnni50,
        logo: oranSodaSvg,
        cover: anni50Cover,
        backCover: backAnni50Cover,
        camera: cameraOrange,
        title: "'50",
        final: backAnni50Final,
        color: '#ff9b00',
        testo1: "FAI UN SALTO NELLA DOLCE VITA DEGLI ANNI '50",
        testo2: "Abbraccia lo stile e l’avventura di una giovinezza eterna, riflessa nel caldo tramonto degli anni d’oro."

    },
    'plmq82660': {
        sfondo: sfondoGiallo,
        lastPage: lastPageYellow,
        backgroundImage: coverAnni60,
        logo: lemonSodaSvg,
        cover: anni60Cover,
        backCover: backAnni60Cover,
        camera: cameraYellow,
        title: "'60",
        final: backAnni60Final,
        color: '#fed303',
        testo1: "LANCIATI NEL RITMO RIVOLUZIONARIO DEGLI ANNI '60",
        testo2: "Entra nel decennio dell’espressione libera e delle rivoluzioni culturali. L’AI ti porterà in un’epoca di colori vivaci e musica indimenticabile."

    },
    'qkzp13570': {
        sfondo: sfondoOrange,
        lastPage: lastPageOrange,
        backgroundImage: coverAnni70,
        logo: oranSodaSvg,
        cover: anni70Cover,
        backCover: backAnni70Cover,
        camera: cameraOrange,
        title: "'70",
        final: backAnni70Final,
        color: '#ff9b00',
        testo1: "RESPIRA LA MAGICA ATMOSFERA DEGLI ANNI '70",
        testo2: "Trasforma il tuo stile con l'energia e la gioia tipiche dei figli dei fiori e lasciati ispirare dall'AI per un look che cattura l'essenza della libertà."
    },
    'uyhr79480': {
        sfondo: sfondoGiallo,
        lastPage: lastPageYellow,
        backgroundImage: coverAnni80,
        logo: lemonSodaSvg,
        cover: anni80Cover,
        backCover: backAnni80Cover,
        camera: cameraYellow,
        title: "'80",
        final: backAnni80Final,
        color: '#fed303',
        testo1: "BUTTATI NEI VIVACI E COLORATI ANNI '80",
        testo2: "Cattura l'essenza delle icone stilistiche e il vivido scintillio che resero indimenticabile il decennio degli eccessi."
    },
    'wvbx63090': {
        sfondo: sfondoGiallo,
        lastPage: lastPageYellow,
        backgroundImage: coverAnni90,
        logo: lemonSodaSvg,
        cover: anni90Cover,
        backCover: backAnni90Cover,
        camera: cameraYellow,
        title: "'90",
        final: backAnni90Final,
        color: '#fed303',
        testo1: "TUFFATI NEL FASCINO ICONICO DEGLI ANNI '90",
        testo2: "Infrangi le regole con lo stile grunge o balla al ritmo dell'eurodance: un decennio di contrapposizioni e trend rivoluzionari ti aspetta."
    },
    'home': {
        backCover: backAnni80Cover,
        final: backAnni90Final,

    }
}

const SubmitMessage = ({ message01, message02, value, color }) => {

    useEffect(() => {
        if (message01 && message01 !== '') document.getElementsByClassName('MuiLinearProgress-bar')[0].style.backgroundColor = color
    }, [message01])


    return (
        <>
            {
                message01 && message01 !== '' ?
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'end', borderRadius: '30px' }}>
                        <div>
                            <p className='loading-text'>{message01}</p>
                            <p className='loading-text'>{message02}</p>
                            <LinearProgress className='progress-bar' variant="determinate" value={message01 !== '' ? value : 0} />
                        </div>


                    </div> : null
            }
        </>
    )
}

const FirstViewMobile = ({ setView, path, view }) => {
    return <div style={{ backgroundImage: view === 1 ? window.innerWidth < 1200 ? `-webkit-linear-gradient(120deg, #161615 50%, ${iconsMap[path].color} 50%)` : `-webkit-linear-gradient(120deg, ${iconsMap[path].color} 50%, #161615 50%)` : view === 2 && window.innerWidth < 1200 ? `-webkit-linear-gradient(120deg, ${iconsMap[path].color} 45%, #161615 45%)` : `#161615` }} className={view === 1 ? window.innerWidth < 1200 ? "App1" : "App2" : view === 2 && window.innerWidth < 1200 ? "App4" : "App3"}>
        <header className="App-header">
            <img src={iconsMap[path].logo} className="App-MainLogo" alt="logo" />
        </header>
        <div style={{ marginTop: '1.5rem', marginBottom: '2rem' }}>
            <img src={iconsMap[path].cover} className="anni-60-cover" alt="anni60Cover" />
        </div>

        <h2 className='firstViewTitle' style={{ color: iconsMap[path].color }}>{iconsMap[path].testo1}</h2>
        <div className='firstViewText'> Scopri il tuo ritratto vintage in un viaggio sorprendente attraverso l'AI.</div>
        <div className='firstViewText'> {iconsMap[path].testo2}</div>
        {iconsMap[path].testo3 && <div className='firstViewText'>{iconsMap[path].testo3}</div>}
        <button onClick={() => setView(1)} className='button-anni' style={{ backgroundColor: iconsMap[path].color }}>Rivivi gli anni {iconsMap[path].title}</button>
    </div>
}

const FirstViewDesktop = ({ setView, path, view }) => {
    return <div style={{ backgroundImage: view === 1 ? window.innerWidth < 1200 ? `-webkit-linear-gradient(120deg, #161615 50%, ${iconsMap[path].color} 50%)` : `-webkit-linear-gradient(120deg, ${iconsMap[path].color} 50%, #161615 50%)` : view === 2 && window.innerWidth < 1200 ? `-webkit-linear-gradient(120deg, ${iconsMap[path].color} 45%, #161615 45%)` : `#161615` }} className={view === 1 ? window.innerWidth < 1200 ? "App1" : "App2" : view === 2 && window.innerWidth < 1200 ? "App4" : "App3"}>
        <header className="App-header1">
            <img src={iconsMap[path].logo} className="App-MainLogo" alt="logo" />
        </header>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '1rem' }}>

            <div style={{ width: '40%', height: '50vh', marginTop: '1.5rem' }}>
                <p className='firstViewTitle' style={{ color: iconsMap[path].color }}>{iconsMap[path].testo1}</p>
                <div className='firstViewText'>Scopri il tuo ritratto vintage in un viaggio sorprendente attraverso l'AI.</div>
                <div className='firstViewText'>{iconsMap[path].testo2}</div>
                {iconsMap[path].testo3 && <div className='firstViewText'>{iconsMap[path].testo3}</div>}
                <button onClick={() => setView(1)} className='button-anni' style={{ backgroundColor: iconsMap[path].color }}>Rivivi gli anni {iconsMap[path].title}</button>
            </div>
            <div style={{ marginTop: '1.5rem' }}>
                <img src={iconsMap[path].cover} className='anni-60-cover' alt="anni-60-cover" />
            </div>

        </div>


    </div>
}


const SecondViewMobile = ({ path, view, setView }) => {
    const device = /iPad|iPhone|iPod/.test(navigator.userAgent)
    
    const contTry = 5
    const hiddenFileInput = useRef(null)
    const [tutorialOpen, setTutorialOpen] = useState(false)
    const [imageUpload, setImageUploaded] = useState(null)
    const [imageURL, setImageURL] = useState(null)
    const [fileUpload, setFileUpload] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [returnVision, setReturnVision] = useState('')
    const [tentative, setTentative] = useState(-1)
    const [loadMessage01, setMessage01] = useState('')
    const [loadMessage02, setMessage02] = useState('')
    const [isSharedEnabled, setIsSharedEnabled] = useState(false)
    const [imageCount, setImageCount] = useState(0)
    const [imageName, setImageName] = useState(null)
    const [done, setDone] = useState(false)
    const [uploadTemp, setUploadTemp] = useState(false)
    const [navigatorCanShare, setNavigatorCanShare] = useState(false)
    const [copiedText, setCopiedText] = useState(false)
    
    let interval = null
    const [progress, setProgress] = useState(1)

    
    const handleTakePhoto = async (dataUri) => {

        fetch(dataUri)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], "selfie", { type: "image/png" })
                setFileUpload(file)
            })
        setImageUploaded(dataUri)
    }


    const handleClick = event => {
        handlerTutorialModal(true)
        setUploadTemp(true)
    }



    const handleChange = (event) => {
        setFileUpload(event.target.files[0])
        const [file] = event.target.files
        if (file) setImageUploaded(URL.createObjectURL(file))
    }


    const getVision = async (image, count) => {
        setMessage01('Stiamo impostando')
        setMessage02('le coordinate temporali...')
        let result = await openAIVision(image)
        if (result.status === 200) {
            setReturnVision(result.message)
            handlerMonsterAI(result.message)
        }
        else {
            if (count < contTry) {
                getVision(image, count + 1)
            }
            else {
                setDone(false)
                setSubmit(false)
                setMessage01('')
                setMessage02('')

            }
        }
    }

    const TransformAction = async () => {

        if (submit) {
            return
        }
        setSubmit(true)

        let reader = new FileReader()
        await reader.readAsDataURL(fileUpload)
        reader.onload = async () => {
            if (returnVision === '') {
                getVision(reader.result, 0)
            }
            else {
                handlerMonsterAI()
            }

        }
        reader.onerror = function (error) {
            console.log('Error: ', error)
            setSubmit(false)
        }

    }

    const _postMonsterAI = async (reader, year, person, glasses, hair) => {

        let result = await postMonsterAi(reader, year, person, glasses, hair, isMobile(), imageName)
        
        if (result.status === 200) {
            const file = await downloadImage2(result.url)
            
            if (navigator.share && navigator.canShare({ url: imageURL, files:[file] })) {
                setNavigatorCanShare(true)
             } else {
                setNavigatorCanShare(false)
             }
            setImageURL(result.url)
            setImageName(result.image_name)
            setDone(true)
            setSubmit(false)
            setSubmit(false)
            setMessage01('')
            setMessage02('')
            setImageCount(imageCount + 1)

        }
        else {
            await _postMonsterAI(reader, year, person, glasses, hair)
        }

    }

    const handlerMonsterAI = async (mensage) => {
        setMessage01('Tieniti forte perché')
        setMessage02('stiamo per atterrare!')

        let _mensage = returnVision
        if (mensage) {
            _mensage = mensage
        }

        _mensage = _mensage.split(';')

        let person = _mensage[0].trim()
        let glasses = _mensage[1].trim() === 'glasses' ? 'glasses' : ''
        let hair = _mensage[2].trim();

        let reader = new FileReader()

        const year = () => {
            if (path === 'xjdi29330') { // years 30
                return `1930s`
            }
            else if (path === 'ghks52750') { // years 50
                return `1950s`
            }
            else if (path === 'plmq82660') { // years 60
                return `1960s`
            }
            else if (path === 'uyhr79480') { // years 80
                return `1980s`
            }
            else if (path === 'wvbx63090') { // years 90
                return `1990s`
            }
            else {
                return `1970s`
            }
        }

        await reader.readAsDataURL(fileUpload)
        reader.onload = async () => {
            await _postMonsterAI(reader.result, year(), person, glasses, hair)
        }
        reader.onerror = function (error) {
            console.log('Error: ', error)
            setSubmit(false)
        }
    }

    useEffect(() => {
        if (!submit) {
            if (tentative === 0) {
                TransformAction()
            }
            else if (tentative > 0 && tentative < 3) {
                TransformAction()

            }
        }

    }, [tentative])

    const handleTrasforma = async () => {
        if(tentative < 0){
            faro.api.pushEvent(`click su trasforma`)
        }else if(tentative === 0){
            faro.api.pushEvent(`click su riprova 1`)
        }else{
            faro.api.pushEvent(`click su riprova 2`)
        }
        clearInterval(interval)
        setProgress(1)
        interval = setInterval(() => {

            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1))
        }, tentative === -1 ? 1000 : 1000)

        if (tentative === 1) {
            Cookies.set(path, true, { expires: 1 })
        }
        if (tentative < 3) {
            setTentative(tentative + 1)
        }

    }
    const handlerSave = () => {
        downloadImage(imageURL)
    }
   

    useEffect(() => {
        if(imageCount === 3){
            setTimeout(() => {
                setView(2)
                setImageCount(0)
            }, 20000);
        }
    }, [imageCount])
    

   

    const handleCapture = (target) => {
        if (target.files) {
          if (target.files.length !== 0) {
            const file = target.files[0];
            const imageSrc = URL.createObjectURL(file);
            handleTakePhoto(imageSrc)
          }
        }
      }

      const handlerTutorialModal = () => {
        setUploadTemp(false)
        setTutorialOpen(true)
      }

      const handleOkTutorialModal = () => {
        faro.api.pushEvent(`click su carica un immagine`)
        if(uploadTemp){
            hiddenFileInput.current.click()
            setTutorialOpen(false)
        }else{
            let label = document.getElementById("icon-button-file");
            label.click()
            setTutorialOpen(false)
        }
        
    }    

    
  
    async function webShare(type) {
        const file = await downloadImage2(imageURL)
       
        try {
            if(type === 'salva'){
                if(navigator.canShare({ files:[file] })){
                    return navigator.share({ files:[file] })
                }else{
                    return handlerSave()
                }
            }else{
                return device ? navigator.share({ files:[file] }) : navigator.share({ url: imageURL,  })
            }
            
           
        } catch (error) {
            console.log('Error:', error)
            if(type === 'salva'){
                return handlerSave()
            }else{
                setIsSharedEnabled(true)
            }
            
        }
      }
  
      const wrapSave = () => {
        faro.api.pushEvent(`click su immagine scaricata`)
        if(navigatorCanShare && device) {
             webShare('salva')
        }else{
            handlerSave()
        }
      }

      const wrapShare = () => {
        
        faro.api.pushEvent(`click su immagine condivisa`)
        
        if(navigatorCanShare){
            webShare('condividi')
        }else{
            setIsSharedEnabled(true)
        }
      }
   
    return <div style={{ backgroundImage: view === 1 ? window.innerWidth < 1200 ? imageURL ? `url(${iconsMap[path].backgroundImage})` : `-webkit-linear-gradient(120deg, #161615 50%, ${iconsMap[path].color} 50%)` : !imageURL ? `url(${iconsMap[path].backgroundImage})` : `-webkit-linear-gradient(120deg, ${iconsMap[path].color} 50%, #161615 50%)` : view === 2 && window.innerWidth < 1200 ? `-webkit-linear-gradient(120deg, ${iconsMap[path].color} 45%, #161615 45%)` : `#161615` }} className={window.innerWidth < 1200 ? 'App1' : 'App2'} >

        <header className="App-header">
            {!done ? <div className='secondViewTitle'>SCATTA UN SELFIE O CARICA UN'IMMAGINE</div> :
                tentative === 0 ? <div className='secondViewTitle'>Ecco il primo risultato,</div>
                : tentative === 1 ? <div className='secondViewTitle'>Ti piace il secondo risultato?</div> :
                <div className='secondViewTitle'></div>
            }
        </header>

       


        {
           uploadTemp ? <TutorialModal2 open={tutorialOpen} handleClose={()=>{
            setUploadTemp(false)
            setTutorialOpen(false)
        }} handleSuccess={handleOkTutorialModal} backgroundColor={iconsMap[path].color}/> :  <TutorialModal open={tutorialOpen} handleClose={()=>setTutorialOpen(false)} handleSuccess={handleOkTutorialModal} backgroundColor={iconsMap[path].color}/>
        }


        {isSharedEnabled && <Dialog style={{ position: 'absolute', bottom: '15%' }} open={isSharedEnabled} onClose={() => setIsSharedEnabled(false)}>
            <div
                style={{
                    background: '#0000',
                    height: '33vh',
                    width: '80vw',
                }}
            >
                <CloseIcon onClick={() => setIsSharedEnabled(false)} style={{ float: 'right', color: 'grey', margin: '0.3rem' }} />
                <h2 style={{ marginTop: '1.5rem', color: 'grey', paddingLeft: '1rem', paddingBottom: '0.5rem', borderBottom: '1px solid lightgrey' }}>Condividi</h2>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '1.5rem' }}>
                    <FacebookShareButton
                        url={imageURL}

                    >
                        <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>


                    <WhatsappShareButton
                        url={imageURL}

                    >
                        <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>

                    <TwitterShareButton

                        url={imageURL}

                    >
                        <XIcon size={40} round={true} />
                    </TwitterShareButton>
                    <TelegramShareButton
                        url={imageURL}

                    >
                        <TelegramIcon size={40} round={true} />
                    </TelegramShareButton>
                    <EmailShareButton
                        url={imageURL}

                    >
                        <EmailIcon size={40} round={true} />
                    </EmailShareButton>
                </div>
                <div onClick={() => {
                    setCopiedText(true)
                    setTimeout(() => {
                        setCopiedText(false)
                    }, 500)

                    navigator.clipboard.writeText(imageURL)
                }
                } style={{
                    cursor: "pointer",
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '0.5rem',
                    opacity: 0.6,
                    marginTop: '1.5rem',
                    width: '70vw',
                    overflow: 'hidden',
                    border: '1px solid grey',
                    borderRadius: '12px',
                    padding: '0.7rem',

                }}>{copiedText ? <CheckIcon style={{ marginRight: '0.3rem', color: '#24d366' }} /> : <ContentCopyIcon style={{ marginRight: '0.3rem' }} />}{imageURL?.slice(0, 30)}...</div>
            </div>
        </Dialog>
        }

        {!done ? <div className='secondViewText'>La nostra Al trasformerà il tuo look e ti porterà indietro nel tempo!</div>
        : tentative === 0 ? <div className='secondViewText' style={{ marginTop: '0.5rem', fontFamily:'Arial,sans-serif'}}>sei pronto a scoprire gli altri due?</div> :
         tentative === 1 ? <div className='secondViewText' style={{ marginTop: '0.5rem', fontFamily:'Arial,sans-serif'}} >Te ne resta ancora uno!</div> : 
         <div className='secondViewText' style={{ marginTop: '0.5rem', fontFamily:'Arial,sans-serif'}}>Fine del viaggio! Condividi il tuo scatto 
         preferito sui social e tagga @lemonsoda_it</div>}
        <div style={{height:done && '60vh' }} className={done ? 'upload-image-main-container-done' : 'upload-image-main-container'}>
            {
                imageUpload &&
                <div style={{ position: 'relative' }}>
                    <img src={imageURL ? `${imageURL}` : `${imageUpload}`} style={{ objectPosition:'left top',  objectFit: 'cover', width: done ? '70vw' : '55vw', height: done ? '43vh' : '35vh', borderRadius: '30px', filter: loadMessage01 && 'brightness(0.3)' }} />

                    <div style={{ width: '100%', position: 'absolute', bottom: '30%', borderRadius: '30px' }}>
                        <SubmitMessage value={progress} color={iconsMap[path].color} message01={loadMessage01} message02={loadMessage02} />
                    </div>

                </div>
            }
            
             <input
            accept="image/*"
            style={{display:'none'}}
            id="icon-button-file"
            type="file"
            capture="environment"
            onChange={(e) => handleCapture(e.target)}
          />

            {done && 
            <div onClick={() => wrapSave()}  style={{ color: '#66676b', marginLeft: '2rem', marginTop: '1rem', float: 'left', fontFamily: 'avenirDemiBold,Arial,sans-serif', fontSize: '1rem' }}>
               
                <img src={downloadIcon} style={{ width: '25px', height: '25px', position: 'relative', bottom: '0.5rem' }} /> <span style={{ position: 'relative', bottom: '1rem', left: '0.5rem' }}>Salva</span>
            </div>}
            {done && <div onClick={() => wrapShare()}  style={{ color: '#66676b', marginRight: '2rem', marginTop: '1rem', float: 'right', fontFamily: 'avenirDemiBold,Arial,sans-serif', fontSize: '1rem' }}><img src={share} style={{ width: '25px', height: '25px', position: 'relative', bottom: '0.5rem' }} /> <span style={{ position: 'relative', bottom: '1rem', }}>Condividi</span></div>}
            {imageURL && 
                <div style={{color:'grey', fontSize:'0.8rem', display:'inline-block'}}>
                    {device ? `Salva l'immagine: verrà scaricata nella sezione file o in archivio. Se non riesci a salvarla, tieni premuto sull'immagine finché non apparirà la finestra di salvataggio.` : `Salva l'immagine: verrà scaricata nella tua galleria/album fotografico, nella sezione file o in archivio.`}  
                </div>
            }
            {!imageUpload && !imageURL &&
                <>
                     <img onClick={handlerTutorialModal} className='camera-yellow' src={iconsMap[path].camera} alt="camera" />
                    <p style={{ color: 'black', fontWeight: 600,  }}>Oppure</p>
                    <div className='upload-image-container' onClick={handleClick}>

                        <input style={{ display: 'none' }} onChange={handleChange} ref={hiddenFileInput} type='file' accept='image/*' />
                        <img className='upload-img' src={upload} alt="upload" />
                        <p className='carica-foto'>Carica Una Foto</p>
                    </div>
                </>
            }
            
        </div>

        {!imageUpload && <div className='thirdViewText' style={{ color:'white', position:'relative', top:'2rem' }}>Scopri nuove versioni di te stesso: <br/>hai tre tentativi!</div>}
        
        {((imageUpload && !done && tentative < 2) || (imageUpload && tentative < 2)) && <button onClick={handleTrasforma} className='trasforma-button' style={{ width: '240px', backgroundColor: iconsMap[path].color, marginTop: imageURL && '1rem' }}>{submit ? <CircularProgress style={{ color: 'white' }} size={24} /> : tentative === -1 ? 'Trasforma' : 'Riprova'}</button>}
        <br />
        {imageURL && <button size={18} onClick={() => {
            faro.api.pushEvent(`click su visita il sito`)
            window.location.href = 'https://lemonsoda.it/special-edition'
        }} className='trasforma-button' style={{ fontSize: '1rem', backgroundColor: 'black', color:'white', border:`1px solid ${iconsMap[path].color}`, marginTop: (imageURL && tentative !== 2) && '1rem' }}>Visita il sito</button>}
        <br/>
        {((imageUpload && !done && tentative === -1) || (imageUpload && tentative < 0)) && <div style={{ position: 'relative', top: '1rem', fontSize: '1rem' }}>oppure <span onClick={() => setImageUploaded(null)} style={{ fontWeight: 'bold', textDecoration: 'underline' }}>carica un'altra foto</span></div>}
        {((!done || tentative < 3) && !imageURL) ? <img className={imageUpload ? 'image-cover' : 'image-cover-2'} src={iconsMap[path].backCover} alt="anni-60-2" />
            : tentative >= 2 ?  <div style={{ marginTop: '2rem' }}>
            <div className='thirdViewText' style={{ color: iconsMap[path].color, fontSize:'0.8rem'  }}>Grazie per aver partecipato!</div>
            <div onClick={() => wrapShare()} className='thirdViewText' style={{ color: iconsMap[path].color, textDecoration: 'underline', color:'white', fontSize:'0.8rem'  }}>Condividi la tua foto sui social <br/> e tagga @lemonsoda_it</div>
        </div> : null}
    </div>
}


const SecondViewDesktop = ({ path, setView }) => {

    const contTry = 5
    const hiddenFileInput = useRef(null)
    const [imageUpload, setImageUploaded] = useState(null)
    const [imageURL, setImageURL] = useState(null)
    const [fileUpload, setFileUpload] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [returnVision, setReturnVision] = useState('')
    const [tentative, setTentative] = useState(-1)
    const [loadMessage01, setMessage01] = useState('')
    const [loadMessage02, setMessage02] = useState('')
    const [imageName, setImageName] = useState(null)
    const [imageCount, setImageCount] = useState(0)
    const [isSharedEnabled, setIsSharedEnabled] = useState(false)
    const [uploadTemp, setUploadTemp] = useState(false)
    const [tutorialOpen, setTutorialOpen] = useState(false)
    const [copiedText, setCopiedText] = useState(false)
    const handleTakePhoto = async (dataUri) => {
        fetch(dataUri)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], "selfie", { type: "image/png" })
                setFileUpload(file)
            })
        setImageUploaded(dataUri)

    }


    const handleClick = event => {
        handlerTutorialModal(true)
    }


    const handleChange = (event) => {
        setFileUpload(event.target.files[0])
        const [file] = event.target.files
        if (file) setImageUploaded(URL.createObjectURL(file))
    }

    const [done, setDone] = useState(false)
    const [progress, setProgress] = useState(1)

    let interval = null

    const getVision = async (image, count) => {
        setMessage01('Stiamo impostando')
        setMessage02('le coordinate temporali...')
        let result = await openAIVision(image)
        if (result.status === 200) {
            setReturnVision(result.message)
            handlerMonsterAI(result.message)
        }
        else {
            if (count < contTry) {
                getVision(image, count + 1)
            }
            else {
                setDone(false)
                setSubmit(false)
                setMessage01('')
                setMessage02('')

            }
        }
    }


    const TransformAction = async () => {

        if (submit) {
            return
        }
        setSubmit(true)

        let reader = new FileReader()

        await reader.readAsDataURL(fileUpload)
        reader.onload = async () => {
            if (returnVision === '') {
                getVision(reader.result, 0)
            }
            else {
                handlerMonsterAI()
            }

        }
        reader.onerror = function (error) {
            console.log('Error: ', error)
            setSubmit(false)
        }

    }

    const _postMonsterAI = async (reader, year, person, glasses, hair) => {

        let result = await postMonsterAi(reader, year, person, glasses, hair, isMobile(), imageName)

        if (result.status === 200) {
            
            setImageURL(result.url)
            setImageName(result.image_name)
            setDone(true)
            setSubmit(false)
            setSubmit(false)
            setMessage01('')
            setMessage02('')
            setImageCount(imageCount + 1)


        }
        else {
            await _postMonsterAI(reader,  year, person, glasses, hair)
        }

    }

    const handlerMonsterAI = async (mensage) => {

        setMessage01('Tieniti forte perché')
        setMessage02('stiamo per atterrare!')

        let _mensage = returnVision
        if (mensage) {
            _mensage = mensage
        }

        _mensage = _mensage.split(';')

        let person = _mensage[0].trim()
        let glasses = _mensage[1].trim() === 'glasses' ? 'glasses' : ''
        let hair = _mensage[2].trim()
      
        let reader = new FileReader()

        const year = () => {
            if (path === 'xjdi29330') { // years 30
                return `1930s`
            }
            else if (path === 'ghks52750') { // years 50
                return `1950s`
            }
            else if (path === 'plmq82660') { // years 60
                return `1960s`
            }
            else if (path === 'uyhr79480') { // years 80
                return `1980s`
            }
            else if (path === 'wvbx63090') { // years 90
                return `1990s`
            }
            else {
                return `1970s`
            }
        }

        await reader.readAsDataURL(fileUpload)
        reader.onload = async () => {
            await _postMonsterAI(reader.result,  year(), person, glasses, hair)
        };

        reader.onerror = function (error) {
            console.log('Error: ', error)
            setSubmit(false)
        };

        }
    

    useEffect(() => {
        if (!submit) {
            setImageURL(null)
            if (tentative === 0) {
                TransformAction()
            }
            else if (tentative > 0 && tentative < 3) {
                TransformAction()

            }
        }

    }, [tentative])

    const handleTrasforma = async () => {
        if(tentative < 0){
            faro.api.pushEvent(`click su trasforma`)
        }else if(tentative === 0){
            faro.api.pushEvent(`click su riprova 1`)
        }else{
            faro.api.pushEvent(`click su riprova 2`)
        }
        clearInterval(interval)
        setProgress(1)
        interval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1))
        }, tentative === -1 ? 1000 : 1000)
        if (tentative === 1) {
            Cookies.set(path, true, { expires: 1 })
        }
        if (tentative < 3) {
            setTentative(tentative + 1)
        }

    }

    useEffect(() => {
        if(imageCount === 3){
            setTimeout(() => {
                setView(2)
                setImageCount(0)
            }, 20000);
        }
    }, [imageCount])


    const handlerSave = () => {
        faro.api.pushEvent(`click su immagine scaricata`)
        downloadImage(imageURL)


    }

    const handleCapture = (target) => {
        if (target.files) {
          if (target.files.length !== 0) {
            const file = target.files[0];
            const imageSrc = URL.createObjectURL(file);
            handleTakePhoto(imageSrc)
          }
        }
      }

      const handlerTutorialModal = () => {
        setUploadTemp(false)
        setTutorialOpen(true)
      }

      const handleOkTutorialModal = () => {
        faro.api.pushEvent(`click su carica un immagine`)
        if(uploadTemp){
            hiddenFileInput.current.click()
            setTutorialOpen(false)
        }else{
            let label = document.getElementById("icon-button-file");
            label.click()
            setTutorialOpen(false)
        }
        
      }



    return <div style={{ backgroundImage: `-webkit-linear-gradient(120deg, ${iconsMap[path].color} 50%, #161615 50%)`, }} className={'App2'}>
        {
          <TutorialModal2 open={tutorialOpen} handleClose={()=>{
            setUploadTemp(false)
            setTutorialOpen(false)
        }} handleSuccess={handleOkTutorialModal} backgroundColor={iconsMap[path].color}/> 
        }
        {imageUpload && <div style={{ width: '100%', zIndex: 1, position: 'absolute', bottom: '40%', left: '3%', borderRadius: '30px' }}>
            <SubmitMessage value={progress} color={iconsMap[path].color} message01={loadMessage01} message02={loadMessage02} />
        </div>
        }
        <div style={{ filter: loadMessage01 && 'opacity(0.3)', height: '100%', width: '100%' }}>
            <header className="App-header1">
                <img src={iconsMap[path].logo} className="App-MainLogo" alt="logo" />
            </header>


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <input
            accept="image/*"
            style={{display:'none'}}
            id="icon-button-file"
            type="file"
            capture="environment"
            onChange={(e) => handleCapture(e.target)}
          />
                <div className='upload-image-main-container' style={{ display: imageUpload && 'flex', justifyContent: imageUpload && 'center', height: imageURL && '72vh' }}>
                    {!imageUpload && !imageURL ? <>
                        <div className='upload-image-container' onClick={handleClick}>
                            <input style={{ display: 'none' }} onChange={handleChange} ref={hiddenFileInput} type='file' accept='image/*' />
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <img style={{ height: '2.8vmin', position: 'relative', top: '1rem' }} src={upload} alt="upload" />
                                <p className='carica-foto'>Carica Una Foto</p>
                            </div>
                            

                        </div>
                        {!imageUpload && <div className='thirdViewText' style={{ color:'black', position:'relative', top:'2rem' }}>Scopri nuove versioni di te stesso: <br/>hai tre tentativi!</div>}
                    </>
                        :

                        <div style={{ textAlign: 'center', }}>
                            {
                                imageUpload &&
                                <div style={{ position: 'relative' }}>
                                    {
                                        submit ?
                                        <Fade in={true} timeout={500}>
                                            <div style={{  display:'flex', width: imageURL ? '22vw' : '22vw', height: '48vh', alignItems:'center', justifyContent:'center', alignContent:'center'}}>
                                                <CircularProgress style={{ color: iconsMap[path].color }} size={50} />
                                            </div>
                                        </Fade>
                                        :
                                        <Fade in={true} timeout={500}>
                                            <img src={imageURL ? `${imageURL}` : `${imageUpload}`} style={{ objectFit: !imageURL && 'cover', width: imageURL ? '22vw' : '22vw', height: imageURL ? '55vh' : '48vh', borderRadius: '30px', position: 'relative', top: '1rem' }} />
                                        </Fade>
                                    }
                                </div>
                            }
                            <div style={{ width: '100%' }}>

                                {
                                    imageURL &&
                                    <div onClick={handlerSave} style={{ color: '#66676b', marginLeft: '4.5rem', marginTop: '1.5rem', float: 'left', cursor: 'pointer', fontFamily: 'avenirDemiBold,Arial,sans-serif', fontSize: '1rem' }}>
                                        <img src={downloadIcon} style={{ width: '25px', height: '25px' }} />
                                        <span style={{ position: 'relative', bottom: '0.5rem', left: '0.5rem' }}>Salva</span>
                                    </div>
                                }
                                {
                                    imageURL &&
                                    <div onClick={() => {
                                        faro.api.pushEvent(`click su immagine condivisa`)
                                        setIsSharedEnabled(true)
                                    }} style={{ color: '#66676b', marginRight: '4.5rem', marginTop: '1.5rem', float: 'right', fontFamily: 'avenirDemiBold,Arial,sans-serif', fontSize: '1rem', cursor:'pointer' }}>
                                        <img src={share} style={{ width: '25px', height: '25px' }} />
                                        <span style={{ position: 'relative', bottom: '0.5rem', }}>Condividi</span>
                                    </div>
                                }
                                {(tentative > -1 && tentative < 2) &&
                                    <button onClick={handleTrasforma} className='trasforma-button' style={{ backgroundColor: iconsMap[path].color, top: imageURL ? '0.4rem' : '2.5rem', }}>
                                        {
                                            false ?
                                                <CircularProgress style={{ color: 'white' }} size={24} />
                                                :
                                                'Riprova'}
                                    </button>
                                }
                                {(!done && tentative < 0) &&
                                    <button onClick={handleTrasforma} className='trasforma-button' style={{ backgroundColor: iconsMap[path].color }}>
                                        {
                                            false ?
                                                <CircularProgress style={{ color: 'white' }} size={24} />
                                                :
                                                tentative < 0 ? 'Trasforma' : 'Riprova'}
                                    </button>
                                }
                                 <br />
                                
                                {((imageUpload && !done && tentative === -1) || (imageUpload && tentative < 0)) && <div style={{ position: 'relative', top: '2.5rem', fontSize: '1rem', color: 'black', cursor: 'pointer' }}>oppure <span onClick={() => setImageUploaded(null)} style={{ fontWeight: 'bold', textDecoration: 'underline' }}>carica un'altra foto</span></div>}
                            </div>
                        </div>
                    }
                </div>
                {isSharedEnabled && <Dialog style={{ position: 'absolute', bottom: '15%' }} open={isSharedEnabled} onClose={() => setIsSharedEnabled(false)}>
            <div
                style={{
                    background: '#0000',
                    height: '33vh',
                    width: '40vw',
                }}
            >
                <CloseIcon onClick={() => setIsSharedEnabled(false)} style={{ float: 'right', color: 'grey', margin: '0.3rem', cursor:'pointer' }} />
                <h2 style={{ marginTop: '1.5rem', color: 'grey', paddingLeft: '1rem', paddingBottom: '0.5rem', borderBottom: '1px solid lightgrey' }}>Condividi</h2>
                <div style={{ display: 'flex',  marginTop: '1.5rem' }}>
                    <FacebookShareButton
                        url={imageURL}
                        style={{marginLeft:'4rem'}}

                    >
                        <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>


                    <WhatsappShareButton
                        url={imageURL}
                        style={{marginLeft:'4rem'}}

                    >
                        <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>

                   
                    <TwitterShareButton

                        url={imageURL}
                        style={{marginLeft:'4rem'}}

                    >
                        <XIcon size={40} round={true} />
                    </TwitterShareButton>
                    <TelegramShareButton
                        url={imageURL}
                        style={{marginLeft:'4rem'}}

                    >
                        <TelegramIcon size={40} round={true} />
                    </TelegramShareButton>
                    <EmailShareButton
                        url={imageURL}
                        style={{marginLeft:'4rem'}}

                    >
                        <EmailIcon size={40} round={true} />
                    </EmailShareButton>
                </div>
                <div onClick={() => {
                    setCopiedText(true)
                    setTimeout(() => {
                        setCopiedText(false)
                    }, 500)

                    navigator.clipboard.writeText(imageURL)
                }
                } style={{
                    cursor: "pointer",
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '1.5rem',
                    opacity: 0.6,
                    marginTop: '1.5rem',
                    width: '35vw',
                    overflow: 'hidden',
                    border: '1px solid grey',
                    borderRadius: '12px',
                    padding: '0.7rem',

                }}>{copiedText ? <CheckIcon style={{ marginRight: '0.3rem', color: '#24d366' }} /> : <ContentCopyIcon style={{ marginRight: '0.3rem' }} />}{imageURL?.slice(0, 30)}...</div>
            </div>
        </Dialog>
        }
                {(tentative < 2 || loadMessage01) &&
                    <div style={{ marginTop: '10rem', width: '30%' }}>
                        <p className='secondViewTitle'>CARICA UN'IMMAGINE</p>
                        <p className='secondViewText'>La nostra Al trasformerà il tuo look e ti porterà indietro nel tempo!</p>
                        {imageURL && <button onClick={() => {
                            faro.api.pushEvent(`click su visita il sito`)
                            window.location.href = 'https://lemonsoda.it/special-edition'
                        }} className='trasforma-button' style={{ backgroundColor: 'black', color:'white', border:`1px solid ${iconsMap[path].color}`,}}>Visita il sito</button>}
                    </div>
                }

                {(tentative >= 2 && !submit) ?
                    <div style={{ marginTop: '10rem', width: '30%' }}>
                        <p className='secondViewTitle'>Grazie per aver partecipato!</p>
                        <p onClick={() => setIsSharedEnabled(true)} className='secondViewTitle' style={{textDecoration:'underline', cursor:'pointer'}}>Condividi la tua foto sui social <br/> e tagga @lemonsoda_it</p>
                        <button onClick={() => setView(2)} className='trasforma-button-2'>OK</button>

                    </div>
                    : null

                }

            </div>
        </div>
    </div>
}


const LastViewMobile = ({ path }) => {
    return <Box sx={{ backgroundImage:  `url(${iconsMap[path].lastPage})`,
    
    '@media (min-width:768px)': {
        backgroundImage: 'none'
    }
    }} className='App1'>
        <header className="App-header2">
            <img src={lemonSodaSvg} className="App-MainLogo2" alt="logo" />
            <img src={oranSodaSvg} className="App-MainLogo2" alt="logo" />
        </header>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>


            <img className={'image-cover-3'} src={iconsMap[path].final} alt="anni-60-2" />
            <div className='lastViewText'>Scopri tutte le altre decadi con Lemonsoda e viaggia nel tempo attraverso <br />il gusto che ami!</div>
            <div><button onClick={() => {
                faro.api.pushEvent(`click su scopri di più`)
                window.location.href = 'https://lemonsoda.it/special-edition'
                }} className='trasforma-button-2' style={{ color: iconsMap[path].color, border:`1px solid ${iconsMap[path].color}`, top:'0.8rem' }}>Scopri di più</button></div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 0 }}>
                <img src={lattinaAllPng} style={{ margin: 0, height: '25vh', }} alt="lattine" />
            </div>
            

        </div>

        <div className='fourViewText'>Seguici sul nostro canale Instagram <br /> per non perdere nessuna novità!</div>
        <div>
            <InstagramIcon onClick={() => {
                faro.api.pushEvent(`click su instagram`)
                window.open('https://www.instagram.com/lemonsoda_it')
            }} sx={{ fontSize: '2rem', color: 'white', marginTop: '1rem',  }} /> 
            <a style={{position:'relative', bottom:'0.5rem', left:'0.2rem'}}  onClick={() => {
                faro.api.pushEvent(`click su instagram`)
                window.open('https://www.instagram.com/lemonsoda_it')
            }} className='fiveViewText'>@lemonsoda_it</a>
        </div>

    </Box>
}

const HomePage = ({ }) => {
    return <>
        <header className="App-header2">
            <img src={lemonSodaSvg} className="App-MainLogo2" alt="logo" />
            <img src={oranSodaSvg} className="App-MainLogo2" alt="logo" />
        </header>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

            <div className='pronti-per'>Pronti a tornare indietro nel tempo</div>
            <div className='per-rivivere'>per rivivere la vostra decade preferita?</div>
            <div  className='stay-tuned'>Stay tuned!</div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3rem' }}>
            <img src={lattinaAllPng} className='lattine-home' alt="lattine" />

            </div>
        </div>

        <div className='fourViewText'>Seguici sul nostro canale instagram <br /> per non perdere nessuna novità!</div>
        <a onClick={() => {
            window.open('https://www.instagram.com/lemonsoda_it')
        }} className='fiveViewTextInstagram'>@lemonsoda_it</a>
        <div><InstagramIcon onClick={() => {
            window.open('https://www.instagram.com/lemonsoda_it')
        }} className='insta'  /></div>

    </>
}

const HomePageDesktop = ({ }) => {
    return <>
        <header className="App-header3">
            <img src={lemonSodaSvg} className="App-MainLogo3" alt="logo" />
            <img src={oranSodaSvg} style={{ marginLeft: '2rem' }} className="App-MainLogo3" alt="logo" />
        </header>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%' }}>
                <div style={{ color: 'white', fontSize: '20pt', marginTop: '7rem', fontFamily: 'avenirDemiBold,Arial,sans-serif' }}>Pronti a tornare indietro nel tempo</div>
                <div style={{ color: 'white', fontSize: '20pt', fontFamily: 'avenirDemiBold,Arial,sans-serif' }}>per rivivere la vostra decade preferita?</div>
                <div style={{ color: '#fed303', fontSize: '25pt', fontWeight: 'bold', marginTop: '2rem', fontFamily: 'avenirDemiBold,Arial,sans-serif' }}>Stay tuned!</div>
                <div className='fourViewText' style={{ marginTop: '3rem', color: 'white' }}>Seguici sul nostro canale instagram <br /> per non perdere nessuna novità!</div>
                <a href="https://www.instagram.com/lemonsoda_it" className='fiveViewTextInstagram' style={{ color: 'white' }}>@lemonsoda_it</a>
                <div><InstagramIcon onClick={() => window.open('https://www.instagram.com/lemonsoda_it')} sx={{ fontSize: '5rem', color: 'white', marginTop: '1rem', cursor: "pointer" }} /></div>
            </div>
            <div style={{ width: '50%', display:'flex', flexDirection:'column', height:'80vh' }}>
                <div style={{ display: 'flex', justifyContent: 'center', right: '10rem', position: 'relative', bottom: '8rem' }}>
                    <img src={lattinaPrimaFilaPng} style={{ margin: 0, height: '50vh', }} alt="lattine" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', bottom: '14rem' }}>
                    <img src={lattinaSecondaFilaPng} style={{ margin: 0, height: '50vh', }} alt="lattine" />

                </div>
            </div>
        </div>



    </>
}



const LastViewDesktop = ({ path }) => {
    return <div style={{ backgroundImage: `url(${iconsMap[path].sfondo})`, minHeight: '100vh' }} className={'App1'}>
        <header className="App-header3">
            <img src={lemonSodaSvg} className="App-MainLogo3" alt="logo" />
            <img src={oranSodaSvg} style={{ marginLeft: '2rem' }} className="App-MainLogo3" alt="logo" />
        </header>

        <div style={{ display: 'flex', flexDirection: 'row', }}>


        <div style={{ marginTop: '3.5rem', textAlign: 'center', width:'80%' }}>
                <div className='lastViewText2'>Scopri tutte le altre decadi con Lemonsoda <br /> e viaggia nel tempo attraverso il gusto che ami!</div>
                    <img src={lattinaAllPng} style={{ margin: 0, height: '35vh', }} alt="lattine" />
            </div>
            <img className={'image-cover-4'} style={{}} src={iconsMap[path].final} alt="anni-60-2" />
        </div>




        <div className='fiveViewText' style={{ bottom: '7.5rem', position: 'relative' }}>Seguici sul nostro canale instagram <br /> per non perdere nessuna novità!</div>
        
        <div><InstagramIcon onClick={() => {
            faro.api.pushEvent(`click su instagram`)
            window.open('https://www.instagram.com/lemonsoda_it')
        }} sx={{ fontSize: '2rem', color: 'white', bottom: '7rem', position: 'relative', cursor: "pointer" }} /><a onClick={() => {
            faro.api.pushEvent(`click su instagram`)
            window.open('https://www.instagram.com/lemonsoda_it')
        }} className='fiveViewText' style={{ bottom: '7.5rem', position: 'relative', left:'0.5rem' }}>@lemonsoda_it</a></div>
        <button onClick={() => {
            faro.api.pushEvent(`click su scopri di più`)
            window.location.href = 'https://lemonsoda.it/special-edition'
        }} className='trasforma-button-2' style={{ backgroundColor: iconsMap[path].color, marginTop:0, bottom:'6.2rem', color:'black', left:'1rem' }}>Scopri di più</button>
    </div>
}



const App = ({ path }) => {
    const [view, setView] = useState(Cookies.get(path) ? 2 : 0)
    // const [view, setView] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        faro.api.pushEvent(`Primo accesso`)
        if(['xjdi29330', 'ghks52750', 'plmq82660', 'qkzp13570', 'uyhr79480', 'wvbx63090'].includes(path) && view === 0){
            if(!ref.value){
                ref.value = new Date()
                faro.api.pushEvent(`Secondo accesso`)
            }
            
        }
        
    }, [])

    if (path === 'home') return window.innerWidth < 1200 ? <Box className='App1' sx={{ 
        backgroundImage: `url(${lastPageYellow})`, 
'@media (min-width:768px)': {
        backgroundImage: 'none'
    } }}> <HomePage path={'home'} /></Box> : <div className='App1' style={{ backgroundImage: `url(${sfondoGiallo})`, minHeight: '100vh' }}> <HomePageDesktop path={'home'} /></div>


    return view === 1 ? window.innerWidth < 1200 ? <SecondViewMobile path={path} view={view} setView={setView} /> : <SecondViewDesktop path={path} view={view} setView={setView} />
        : view === 2 ? window.innerWidth < 1200 ? <LastViewMobile path={path} view={view} setView={setView} /> : window.innerWidth > 1200 ? <LastViewDesktop path={path} view={view} setView={setView} /> : <SecondViewDesktop path={path} view={view} /> : window.innerWidth < 1200 ? <FirstViewMobile path={path} view={view} setView={setView} /> : <FirstViewDesktop path={path} view={view} setView={setView} />





}

export default App
