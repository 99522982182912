import { Box, Modal, Typography } from "@mui/material"


import icon from '../../images/iconCam.svg'

const styles = {
    modal:{
        display:'flex',
        flexDirection:'column',
        padding:'20px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '0px solid #000',
        boxShadow: '6px 6px 6px grey',
        borderRadius: '30px',
        fontfamily:'avenirDemiBold,Arial,sans-serif',
        '@media (min-width:1281px)': {
            width: '30%'
        }
    },
    iconArea:{display:'flex', justifyContent:'center', margin:0, padding:0,  marginBottom:'10px'},
    icon: {
        width: '50px',
        height:'50px',
        margin:0, 
        padding:0
    },
    titleArea:{
        width: '80vw',
        display:'flex', 
        flexDirection:'column', 
        justifyContent:'center', 
        alignItems:'center', 
        margin:0, 
        padding:0,
        marginBottom:'20px',
        '@media (min-width:1281px)': {
            width: '30vw',
        }
    },
    title:{
        fontSize:'14pt', 
        fontWeight:800
    },
    subtitle:{
        fontSize:'14pt', 
    },
    description:{
        fontSize:'12pt', 
        fontWeight:500,
        margin:0,
        marginBottom:'20px'
    },
    buttonArea:{ display:'flex', justifyContent:'center', margin:0, padding:0,},
    button:{
        width: '300px',
        height: '60px',
        fontSize: '1.4rem',
        borderRadius: '30px',
        border: '1px solid #000',
        color: 'black',
        outline:'none',
        border:'none',
        cursor:'pointer'

    },
    buttonText:{
        fontSize:'16pt', 
        fontWeight:800
    }
  };


const TutorialModal = ({open, handleClose, handleSuccess, backgroundColor}) => {


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={styles.modal}>
                <Box sx={styles.iconArea}>
                    <Box component="img" alt="camera icon" src={icon} sx={styles.icon} />
                </Box>
                <Box sx={styles.titleArea}>
                        <Typography sx={styles.title} component="h2">
                            PER UN RISULTATO MIGLIORE
                        </Typography>
                        <Typography sx={styles.subtitle} component="p">
                            Scegli una foto:
                        </Typography>
                </Box>
                
                <Typography sx={styles.description} component="p">
                    1 . ad alta risoluzione
                </Typography>
                <Typography sx={styles.description} component="p">
                    2. luminosa e senza elementi sullo sfondo
                </Typography>
                <Typography sx={styles.description} component="p">
                    3. in cui ci sei solo tu, meglio se in primo piano
                </Typography>
                
                <Box sx={styles.buttonArea}>
                    <button onClick={handleSuccess} style={{...styles.button, backgroundColor:backgroundColor}}>
                        <Typography sx={styles.buttonText} component="p">
                            OK
                        </Typography>
                    </button>
                </Box>
            </Box>
            </Modal>
    )

}


export default TutorialModal