import axios from 'axios';

export default src => {
    
    axios({
        url: src,
        method: 'GET',
        responseType: 'blob', // Important part here is blob  
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        
        link.href = url;
        link.setAttribute('download', 'image.png'); // or any other extension
        document.body.appendChild(link);
        
        link.click();
     });
  };
  