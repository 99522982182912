import create_openAI from "./create_openAI";


const openAIVision = async (file) => {

  const url = 'https://app.admind.ai/lemonsoda/vision/1/'
  //const url = 'http://192.168.1.9:8000/lemonsoda/vision/1/'

  let sendText = `Describe the gender details of the person in this image, whether they wear glasses and the hair color. Your answer must not contain any comment but your answer. Your answer must look like this:

  "(man / woman / little girl / little boy); (glasses / no glasses); (Hair color is [color] / [bald])"`

  let result = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    })

  

  if (result.status === 200){
    let dados = await result.json();
    if (dados.text){
      sendText = dados.text
    }
  }

  
  try {

    const openai = create_openAI()

    const generateVisionPromise = await openai.chat.completions.create({
      model: "gpt-4-vision-preview",
      max_tokens: 30,
      messages: [
        {
          role: "user",
          content: [
            { type: "text", text: sendText },
            {
              type: "image_url",
              image_url: {
                "url": file,
              },
            },
          ],
        },
      ],
    });

    const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error('vision generation timed out'));
        }, 30000);
    });

    const result = await Promise.race([generateVisionPromise, timeoutPromise]);
  
    if (!result.choices || !result.choices[0] || !result.choices[0].message || !result.choices[0].message.content) {
          throw new Error("Vision generation is missing in the response.");
      }
    return {
        status: 200,
        message: result.choices[0].message.content
    };
  } catch (error) {
    console.error("Error generating image:", error);
    return {
        status: 500,
        error: "An error occurred while generating the image."
    };
  }

 }

 export default openAIVision